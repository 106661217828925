import { Injectable } from '@tanbo/vue-di-plugin'
import Cookie from 'js-cookie'
import wx from 'weixin-js-sdk'
import { wxOauth } from '@/services/wechat/wxOauth.service'
import { Http } from '@zhfe/common-library'
import { UserService } from '@/services/user.service'
// @ts-ignore
import { formatDate } from '@shared/utils/base'
import { ConfigService } from '@/services/config.service'
import { CookieService } from '@/services/cookie.service'
import { ReportService } from '@/services/report.service'
import { BridgeService } from '@/services/bridge.service'

/** ******************** 登录接口START *****************************/
export interface LoginRequestParams {
  code: string // 用户授权code
}

export interface LoginUserInfo {
  open_id: string // open_id用户标识
  sex: number // 性别,0未知; 1男性; 2女性
  name: string // 昵称
  avatar: string // 头像
  login_type: number // 登录方式(1-微信, 2-qq, 3-手机)
}

export interface LoginTokenInfo {
  token: string // token
  check_time_ms: string // 下次刷新用户token的时间间隔,单位毫秒
}

export interface LoginResponseData {
  user_info: LoginUserInfo // 用户详情
  token_info: LoginTokenInfo // 用户token详情
}

/** ******************** 登录接口END *****************************/

/** ******************** 支付接口START *****************************/
export interface PayQueryParams {
  payapp: string // 支付的APP，由服务端分配
  product_id: string // 付费产品ID，预先配置好
  openid: string // 微信openid
  client_extra?: any // 见备注
}

export interface PayWxResp {
  prepayid: string // （调起支付相关参数）
  appid: string // （调起支付相关参数）
  noncestr: string // （调起支付相关参数）
  timestamp: string // （调起支付相关参数）
  sign: string // （调起支付相关参数）
  sign_type: string // （调起支付相关参数）
  package: string // （调起支付相关参数）
}

export interface PayResponseData {
  order_id: string // 预订单ID，全局唯一，以后查询也用这个ID
  fee: number // 金额，单位分
  fee_type?: string // 货币，默认CNY
  expires: string // 过期时间，客户端只能在这个时间之前支付
  wx_resp: PayWxResp // 微信调起支付需要的参数
}

export interface PaySettingRequestParams {
  /** 套餐组id列表 */
  group_ids: string[]
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous_scene: boolean
}

export interface Plans {
  /** 支付平台(wx, ali) */
  pay_platform: string
  /** 签约模板id */
  plan_id: string
}

export interface PageDetail {
  /** 底部文案 */
  bottom?: string
  /** 底部价格文案样式 0:不展示 1:展示文字 2: 文字加删除线 */
  bottom_type?: number
  /** 现价(元) */
  current_fee?: number
  /** 折扣文案 */
  discount_desc?: string
  /** 优惠多少钱(元) */
  discount_fee?: number
  /** 是否终身的套餐展示 */
  is_diamond?: boolean
  /** 原价(元) */
  origin_fee?: number
  /** selected_type: 0 # 选中套餐后右下角的显示，0:不显示 1: 签约勾选套餐 2: 钻石/超级会员描述 */
  selected_type?: number
  /** # 角标文字(title_type=1) */
  title?: string
  /** 套餐倒计时结束时间戳，用于倒计时，没有则为0(title_type=3) */
  title_end_time?: number
  /** 角标图片(title_type=2) */
  title_img?: string
  /** 套餐倒计时开始时间戳，用于倒计时，没有则为0(title_type=3) */
  title_start_time?: number
  /** 角标展示形式 0:不展示 1:展示文字 2:展示图片 3:展示倒计时 */
  title_type?: number
  /** 服务于超级会员页，用于展示中间价格旁边的带划线的价格 */
  center_fee?: number
}

export interface ContinuousConfig {
  /** 签约模板 */
  plans: Plans[]
}

export interface PayPackages {
  /** 套餐id */
  product_id: string
  /** 是否为签约套餐场景(true:则返回包含签约的套餐组 false:则返回不含签约的套餐组) */
  is_continuous: boolean
  /** PayPackageContinuous 签约套餐的配置 */
  continuous_config?: ContinuousConfig
  /** 页面展示信息(透传apollo配置), 具体定义见apix备注 */
  page_detail?: PageDetail
}

export interface PayGroups {
  /** 支付套餐组id */
  group_id: string
  /** 默认选中套餐id */
  default_product_id: string
  /** 支付套餐 */
  pay_packages: PayPackages[]
}

export interface PaySettingResponseData {
  /** 支付套餐组列表 */
  pay_groups: PayGroups[]
  /** 服务端时间戳 */
  server_timestamp_ms: string
}

/** ******************** 支付接口END *****************************/

/** ******************** 配置接口START *****************************/

export interface SdkConfigRequestParams {
  url: string // url
}

export interface SdkConfigResponseData {
  timestamp: string // 时间戳
  nonce_str: string // 随机字符串
  signature: string // 签名
  sign_type: string // 签名方式
}

export interface CouponRequestParams {
  /** 优惠券配置id(具体细看apollo的coupon配置) */
  group_coupon_conf_id: string
}

export interface CouponPageDetail {
  /** 优惠卷的价格 */
  discount: string
}

export interface CouponList {
  /** 使用了优惠券的套餐id */
  coupon_product_id: string
  page_detail: CouponPageDetail
}

export interface CouponResponseData {
  /** 使用优惠券的套餐组(如果没有对应配置或者tryno不匹配，则数据为空) */
  coupon_group: string
  /** 没有使用优惠券的套餐组(如果没有对应配置或者tryno不匹配，则数据为空) */
  no_coupon_group: string
  /** 优惠券配置(如果没有对应配置或者tryno不匹配，则数据为空) */
  coupon_list: CouponList[]
}

/** ******************** 配置接口END *****************************/

/** ******************** 微信接口START *****************************/

export interface WxConfigResponseData {
  nonce_str: string
  timestamp: string
  signature: string
}

export interface WxWebData {
  noncestr?: string
  package?: string
  sign?: string
  sign_type?: string
  timestamp?: string
}

export interface CommonParams {
  open_id: string
  token: string
}

/** ******************** 微信接口END *****************************/

export const interestList = [
  {
    label: '电脑一键优化',
    img: require('./_images/cards/one_optimization.png'),
  },
  {
    label: '恶意弹窗拦截',
    hot: true,
    img: require('./_images/cards/popup_intercept.png'),
  },
  {
    label: '垃圾清理',
    img: require('./_images/cards/garbage_clean.png'),
  },
  {
    label: '电脑加速',
    img: require('./_images/cards/computer_speed.png'),
  },
  {
    label: '隐私清理',
    img: require('./_images/cards/privacy_clean.png'),
  },
  {
    label: 'C盘瘦身',
    img: require('./_images/cards/system_slim.png'),
  },
  {
    label: '碎片清理',
    new: true,
    img: require('./_images/cards/clean_king.png'),
  },
  {
    label: '软件管理',
    new: true,
    img: require('./_images/cards/software_manage.png'),
  },
  {
    label: '智能护眼',
    img: require('./_images/cards/eye.png'),
  },
  {
    label: '锁屏壁纸',
    img: require('./_images/cards/lock_screen_wallpaper.png'),
  },
  {
    label: '天气',
    img: require('./_images/cards/weather.png'),
  },
  {
    label: '专属VIP客服',
    img: require('./_images/cards/customer.png'),
  },
  {
    label: '纯净无广告',
    img: require('./_images/cards/no_advert.png'),
  },
  {
    label: '尊贵身份标识',
    img: require('./_images/cards/honorable.png'),
  },
  {
    label: '更多功能 敬请期待',
    type: 'line_feed',
    img: require('./_images/cards/more_func.png'),
  }
]

@Injectable()
export class IndexService {
  userMarkKey = 'UMK'

  /** 支付上报 */
  client_extra = {
    version: this.reporter.report_version, /** 埋点版本号 */
    vip_version: this.cookie.vipVersion, /** 会员客户端版本 */
    show_from: '27', /** 会员页展示来源 */
    fun_from: '15', /** 权益来源 */
    vip_status: this.user.isVip ? '1' : '2', /** 是否会员 1：是，2：否 */
    current_uuid: this.cookie.uuid, /** 产品的uuid */
    current_tryno: this.config.getTryno, /** 产品的tryno */
    current_svrid: this.cookie.svrid, /** 产品的svrid */
    tid1: String(this.bridge.tidInfo.tid1), /** 主通道号（内通道号） */
    tid2: String(this.bridge.tidInfo.tid2), /** 副通道号（内通道号） */
    tod1: String(this.bridge.tidInfo.tod1), /** 主通道号（外通道号） */
    tod2: String(this.bridge.tidInfo.tod2), /** 副通道号（外通道号） */
  }

  constructor(
    private http: Http,
    private user: UserService,
    private config: ConfigService,
    private cookie: CookieService,
    private reporter: ReportService,
    private bridge: BridgeService
  ) {
  }

  report = this.reporter.webReport({
    show_from: '27',
    fun_from: '15',
    vip_version: this.cookie.vipVersion, /** 客户端版本 */
    version: this.reporter.report_version, /** 埋点版本 */
    product_id: this.cookie.productId, /** 产品id */
    tryno: this.config.getTryno
  }, 'infockeniu_vip_paypage:165 product_id:int version:byte vip_version:int show_from:string fun_from:string')

  /**
   * @description 微信配置初始化
   */
  wxConfigInit(wxConfig: WxConfigResponseData, onSuccess?: () => void) {
    wx.config({
      debug: false,
      appId: this.config.wxAppId,
      timestamp: wxConfig.timestamp,
      nonceStr: wxConfig.nonce_str,
      signature: wxConfig.signature,
      jsApiList: ['chooseWXPay']
    })

    wx.ready(function () {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'],
        success: function () {
          onSuccess && onSuccess()
        },
        fail: function (err: any) {
          console.error('check api fail:', err)
        }
      })
    })
  }

  /**
   * @description 调起微信支付
   */
  chooseWxPay (
    payData: WxWebData,
    onSuccess?: () => void,
    onFail?: () => void,
    onCancel?: () => void
  ) {
    wx.chooseWXPay({
      timestamp: payData.timestamp,
      nonceStr: payData.noncestr,
      package: payData.package,
      signType: payData.sign_type,
      paySign: payData.sign,
      success: onSuccess,
      fail: () => {
        onFail && onFail()
        wx.closeWindow()
      },
      cancel: onCancel
    })
  }

  /**
   * @description 获取用户记号信息
   */
  getUserMark () {
    const um = Cookie.get(this.userMarkKey)
    // eslint-disable-next-line no-mixed-operators
    return um && JSON.parse(um) || { open_id: '', token: '' }
  }

  /**
   * @description 设置用户记号信息
   */
  setUserMark (user: CommonParams) {
    Cookie.set(this.userMarkKey, JSON.stringify(user), { expires: 7 })
  }

  /**
   * @description 清除用户记号信息
   */
  clearCookie () {
    Cookie.remove(this.userMarkKey)
  }

  /**
   * @description 静默授权
   */
  silentWxAuth () {
    wxOauth(this.config.wxAppId, 'snsapi_userinfo', 'active')
  }

  /**
   * @description 时间格式化
   */
  handleDate(stamp: number) {
    // @ts-ignore
    return formatDate(stamp)
  }

  /**
   * @description 获取产品功能id
   */
  private static get getFunctionId () {
    const url = location.href

    if (url.indexOf('/mobile/membercenter/activity') > -1) {
      return 33
    }

    return 32
  }

  /**
   * @description 登录请求
   */
  requestLogin (params: LoginRequestParams) {
    return this.http.post<LoginResponseData>('/account/login/wx_web', params)
  }

  /**
   * @description 请求用户信息
   */
  requestUserInfo (params = {}) {
    return this.user.requestUserInfo(params)
  }

  /**
   * @description 获取js-sdk配置
   */
  requestJsSdkConfig (params: SdkConfigRequestParams) {
    return this.http.post<SdkConfigResponseData>('/pay/wx/jsapi_conf', params)
  }

  /**
   * @description 优惠券配置接口
   */
  requestCouponConfig (params: CouponRequestParams) {
    return this.http.post<CouponResponseData>('/coupon/group_conf', params)
  }

  /**
   * @description 获取套餐列表
   */
  requestPaySettings (params: PaySettingRequestParams) {
    return this.http.post<PaySettingResponseData>('/paypackagebase/list_group', params)
  }

  /**
   * @description 请求支付
   */
  requestPay (params: PayQueryParams) {
    return this.http.post<PayResponseData>('/baoquvip/payv2/wx/jsapi', params)
  }
}
