// @ts-ignore
import { getUrlParam, getParamStrByObj } from '@shared/utils/base'

/**
 * 微信授权登录
 * @param appid 公众号的唯一标识
 * @param scope 应用授权作用域(snsapi_base: 不弹出授权页面，直接跳转，只能获取用户openid; snsapi_userinfo: 弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 )
 * @param state 重定向后会带上state参数，开发者可以填写a-zA-Z0-9的参数值，最多128字节
 */
export function wxOauth (appid: string, scope = 'snsapi_userinfo', state = '') {
  let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?'
  const path = window.location.href.split('?')[0] || ''
  let search = window.location.href.split('?')[1] || ''

  search = decodeURIComponent(search)

  // 如果连接里面有state和code,先去掉
  search = search.replace(`&state=${getUrlParam('state')}`, '')
  search = search.replace(`&code=${getUrlParam('code')}`, '')

  const redirectUri = path + (search ? '?' + search : '')
  const params = {
    appid,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope,
    state
  }

  url = url + getParamStrByObj(params) + '#wechat_redirect'

  window.location.href = url
}
